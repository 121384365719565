/* eslint-disable no-new */
import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import { Plugin as FragmentPlugin } from 'vue-fragment';
import vClickOutside from 'v-click-outside';
import DesignsPageContainer from './containers/DesignsPageContainer.vue';
import OverviewPageContainer from './containers/ManageProfile/OverviewPageContainer.vue';
import Callout from './components/shared/Callout/Callout.vue';
import InviteWizardModal from './components/manage-project/overview/InviteWizardModal/InviteWizardModal.vue';
import InvitationModal from './components/my-account/designer/overview/InvitationModal/InvitationModal.vue';
import ProjectsPageContainer from './containers/ManageAccount/ProjectsPageContainer.vue';
import PrintOrdersPageContainer from './containers/ManageAccount/PrintOrdersPageContainer.vue';
import WebsiteOrdersPageContainer from './containers/ManageAccount/WebsiteOrdersPageContainer.vue';
import MessagesPageContainer from './containers/ManageAccount/MessagesPageContainer.vue';
import ViewMessagePageContainer from './containers/ManageAccount/ViewMessagePageContainer.vue';
import FavoriteDesignersPageContainer from './containers/ManageAccount/FavoriteDesignersPageContainer.vue';
import ManageSubscriptionsPageContainer from './containers/ManageAccount/ManageSubscriptionsPageContainer.vue';
import AccountSettingsPageContainer from './containers/ManageAccount/AccountSettingsPageContainer.vue';
import WhitelabelPageContainer from './containers/ManageAccount/WhitelabelPageContainer.vue';
import NotificationsPageContainer from './containers/ManageProfile/NotificationsPageContainer.vue';
import MessagesProfilePageContainer from './containers/ManageProfile/MessagesPageContainer.vue';
import ViewMessageProfilePageContainer from './containers/ManageProfile/ViewMessagePageContainer.vue';
import InvitesPageContainer from './containers/ManageProfile/InvitesPageContainer.vue';
import PayoutLogsPageContainer from './containers/ManageProfile/PayoutLogsPageContainer.vue';
import PayoutMethodPageContainer from './containers/ManageProfile/PayoutMethodPageContainer.vue';
import WatchlistPageContainer from './containers/ManageProfile/WatchlistPageContainer.vue';
import DesignerServicesPageContainer from './containers/ManageProfile/DesignerServicesPageContainer.vue';
import DesignerServicesEditPageContainer from './containers/ManageProfile/DesignerServicesEditPageContainer.vue';
import SubmissionsPageContainer from './containers/ManageProfile/SubmissionsPageContainer.vue';
import ProfileSettingsPageContainer from './containers/ManageProfile/ProfilePageContainer.vue';
import DesignFeedbackPageContainer from './containers/ManageProfile/DesignFeedbackPageContainer.vue';
import SubmitSourceFilesPageContainer from './containers/ManageProfile/SubmitSourceFilesPageContainer.vue';
import { createDesignsStore } from './store';

const vueInit = () => {
  Vue.use(Vuex);
  Vue.use(VueRouter);
  Vue.use(FragmentPlugin);
  Vue.use(vClickOutside);

  if (document.getElementById('winning-designs-page-container') !== null) {
    const store = createDesignsStore();
    const router = new VueRouter({ mode: 'history' });

    new Vue({
      el: '#winning-designs-page-container',
      store,
      router,
      components: {
        DesignsPageContainer,
      },
    });
  }

  if (document.getElementById('designs-page-container') !== null) {
    const store = createDesignsStore();
    const router = new VueRouter({ mode: 'history' });

    new Vue({
      el: '#designs-page-container',
      store,
      router,
      components: {
        DesignsPageContainer,
      },
    });
  }

  if (document.getElementById('overview-page-callouts') !== null) {
    new Vue({
      el: '#overview-page-callouts',
      components: {
        Callout,
      },
    });
  }

  if (document.getElementById('overview-page-invite-wizard') !== null) {
    const router = new VueRouter({ mode: 'history' });

    new Vue({
      el: '#overview-page-invite-wizard',
      router,
      components: {
        InviteWizardModal,
      },
    });
  }

  if (document.getElementById('brief-page-callouts') !== null) {
    new Vue({
      el: '#brief-page-callouts',
      components: {
        Callout,
      },
    });
  }

  if (
    document.getElementById(
      'design-page-heading-buy-another-design-callout',
    ) !== null
  ) {
    new Vue({
      el: '#design-page-heading-buy-another-design-callout',
      components: {
        Callout,
      },
    });
  }

  if (document.getElementById('projects-page-container') !== null) {
    const router = new VueRouter({ mode: 'history' });

    new Vue({
      el: '#projects-page-container',
      router,
      components: {
        ProjectsPageContainer,
      },
    });
  }

  if (document.getElementById('print-orders-page-container') !== null) {
    const router = new VueRouter({ mode: 'history' });

    new Vue({
      el: '#print-orders-page-container',
      router,
      components: {
        PrintOrdersPageContainer,
      },
    });
  }

  if (document.getElementById('website-orders-page-container') !== null) {
    const router = new VueRouter({ mode: 'history' });

    new Vue({
      el: '#website-orders-page-container',
      router,
      components: {
        WebsiteOrdersPageContainer,
      },
    });
  }

  if (document.getElementById('messages-page-container') !== null) {
    const router = new VueRouter({ mode: 'history' });

    new Vue({
      el: '#messages-page-container',
      router,
      components: {
        MessagesPageContainer,
      },
    });
  }

  if (document.getElementById('view-message-page-container') !== null) {
    const router = new VueRouter({ mode: 'history' });

    new Vue({
      el: '#view-message-page-container',
      router,
      components: {
        ViewMessagePageContainer,
      },
    });
  }

  if (document.getElementById('favorite-designers-page-container') !== null) {
    const router = new VueRouter({ mode: 'history' });

    new Vue({
      el: '#favorite-designers-page-container',
      router,
      components: {
        FavoriteDesignersPageContainer,
      },
    });
  }

  if (document.getElementById('manage-subscriptions-page-container') !== null) {
    const router = new VueRouter({ mode: 'history' });

    new Vue({
      el: '#manage-subscriptions-page-container',
      router,
      components: {
        ManageSubscriptionsPageContainer,
      },
    });
  }

  if (document.getElementById('account-settings-page-container') !== null) {
    new Vue({
      el: '#account-settings-page-container',
      components: {
        AccountSettingsPageContainer,
      },
    });
  }

  if (document.getElementById('whitelabel-page-container') !== null) {
    new Vue({
      el: '#whitelabel-page-container',
      components: {
        WhitelabelPageContainer,
      },
    });
  }
  if (document.getElementById('overview-page-container') !== null) {
    new Vue({
      el: '#overview-page-container',
      components: {
        OverviewPageContainer,
      },
    });
  }

  if (document.getElementById('notifications-page-container') !== null) {
    new Vue({
      el: '#notifications-page-container',
      components: {
        NotificationsPageContainer,
      },
    });
  }
  if (document.getElementById('watchlist-page-container') !== null) {
    new Vue({
      el: '#watchlist-page-container',
      components: {
        WatchlistPageContainer,
      },
    });
  }
  if (document.getElementById('invitation-modal') !== null) {
    new Vue({
      el: '#invitation-modal',
      components: {
        InvitationModal,
      },
    });
  }
  if (document.getElementById('invites-page-container') !== null) {
    new Vue({
      el: '#invites-page-container',
      components: {
        InvitesPageContainer,
      },
    });
  }
  if (document.getElementById('messages-profile-page-container') !== null) {
    new Vue({
      el: '#messages-profile-page-container',
      components: {
        MessagesProfilePageContainer,
      },
    });
  }
  if (document.getElementById('view-message-profile-page-container') !== null) {
    const router = new VueRouter({ mode: 'history' });

    new Vue({
      el: '#view-message-profile-page-container',
      router,
      components: {
        ViewMessageProfilePageContainer,
      },
    });
  }
  if (document.getElementById('payout-logs-page-container') !== null) {
    new Vue({
      el: '#payout-logs-page-container',
      components: {
        PayoutLogsPageContainer,
      },
    });
  }
  if (document.getElementById('payout-method-page-container') !== null) {
    new Vue({
      el: '#payout-method-page-container',
      components: {
        PayoutMethodPageContainer,
      },
    });
  }
  if (document.getElementById('designer-services-page-container') !== null) {
    new Vue({
      el: '#designer-services-page-container',
      components: {
        DesignerServicesPageContainer,
      },
    });
  }
  if (
    document.getElementById('designer-services-edit-page-container') !== null
  ) {
    new Vue({
      el: '#designer-services-edit-page-container',
      components: {
        DesignerServicesEditPageContainer,
      },
    });
  }
  if (document.getElementById('submissions-page-container') !== null) {
    new Vue({
      el: '#submissions-page-container',
      components: {
        SubmissionsPageContainer,
      },
    });
  }
  if (document.getElementById('profile-settings-page-container') !== null) {
    new Vue({
      el: '#profile-settings-page-container',
      components: {
        ProfileSettingsPageContainer,
      },
    });
  }
  if (document.getElementById('design-feedback-page-container') !== null) {
    new Vue({
      el: '#design-feedback-page-container',
      components: {
        DesignFeedbackPageContainer,
      },
    });
  }
  if (document.getElementById('submit-source-files-page-container') !== null) {
      new Vue({
          el: '#submit-source-files-page-container',
          components: {
            SubmitSourceFilesPageContainer,
          },
      });
  }
};

window.vueInit = vueInit;
